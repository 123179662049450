import { GRADES, TOTAL_CRITERIA } from "commom/consts/evaluations/consts-evaluations";
import { createContext, useContext, useState } from "react";
import evaluationClient from "services/client/evaluation-client";
import { setStrGradesToNumber } from "services/form-process/process-evaluation-form"

const EvaluationContext = createContext({});
EvaluationContext.displayName = 'Avaliação';

/**
 * A representation of the project object.
 * @typedef {Object} Project
 * 
 * @property {number} id
 * @property {string} title
 * @property {string} abstract
 * @property {string} code
 * @property {string} febracev_page
 * @property {string} full_paper_url
 * @property {string} video_url
 * @property {string} poster_url
 * @property {string} logbook_url
 */

/**
 * A representation of the evaluation sheet (ficha de avaliação) used by the evaluator
 * @typedef EvaluationSheet
 * 
 * @property {Array<Project>} projects - List of projects on a sheet.
 * @property {Object} evaluator - The evaluator data.
 * @property {Date} expiration_date
 */

/**
 * A representation of the sheet (ficha); in backend is called ProjetoAvaliador
 * @typedef Sheet
 * 
 * @property {string} uuid - In backend we have: ficha_digital_codigo, but here, in frontend,
 * we want the uuid instead the code.
 * @property {('aberto'|'preenchimento_avaliador'|'preenchimento_equipe'|'expirou')} status -
 * The status of the sheet.
 * @property {number} total_evaluated_projects
 */

/**
 * Provide the global state to Evaluation
 * @param {{children:JSX.Element}} {children} -> Child element of the provider that will have access to the provided states.
 * @returns 
 */
const EvaluationProvider = ({ children }) => {
    const [evaluationSheet, setEvaluationSheet] = useState();
    const [evaluationGrades, setEvaluationGrades] = useState({});
    const [sheets, setSheets] = useState([]);

    return (
        <EvaluationContext.Provider value={{
            evaluationSheet,
            setEvaluationSheet,
            evaluationGrades,
            setEvaluationGrades,
            sheets,
            setSheets
        }}>
            {children}
        </EvaluationContext.Provider>
    )
}

/**
 * Save the data about evaluation in a global state.
 * @returns Evaluation sheet state and the and the assessments carried out
 */
const useEvaluationContext = () => {
    const {
        evaluationSheet,
        setEvaluationSheet,
        evaluationGrades,
        setEvaluationGrades,
        sheets,
        setSheets
    } = useContext(EvaluationContext);

    /**
     * Get and save in state the evaluation sheet.
     * @param {string} uuid The uuid genereate after login. Will come from url
     * @returns {Promise<EvaluationSheet>} A promise. If the promise has been resolved, will return void, otherwise, if the
     * promise has failed, will return the error (request error).
     */
    const getEvaluationSheet = uuid => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await evaluationClient.getEvaluationSheet(uuid);

                setEvaluationSheet(response.data);

                resolve(response.data);
            }
            catch (err) {
                reject(err);
            }
        })
    }

    /**      
     * Get all evaluator sheets
     * @param {string} evaluatorUuid 
     * @returns {Promise<Array<Sheet>>} An array contains all uids and statuses referring to a sheet
     */
    const getEvaluatorSheets = evaluatorUuid => {
        return new Promise(async (resolve, reject) => {
            try {                
                const response = await evaluationClient.getEvaluatorSheets(evaluatorUuid);

                setSheets(response.data);

                resolve(response.data);
            }
            catch (err) {
                reject(err);
            }
        })
    }

    /**
     * Using the project code, it's will be search a project by your code.
     * @param {string} projectCode 
     * @returns {Project|undefined}
     */
    const getProjectByCode = projectCode => {
        const projects = evaluationSheet?.projects || [];

        return projects.find(project => project.code === projectCode);
    }

    /**
     * Saves an evaluation performed in the context state.
     * @param {*} evaluationData 
     */
    const saveEvaluation = evaluationData => {
        setEvaluationGrades(
            {
                ...evaluationGrades,
                ...evaluationData
            }
        )
    }

    const hasProjectEvaluated = projectCode => projectCode in evaluationGrades;

    const calcProjectGrades = projectCode => {
        if (!hasProjectEvaluated(projectCode)) {
            throw new Error(`The project ${projectCode} was not evaluated.`);
        }

        const maxScore = (GRADES.length - 1) * TOTAL_CRITERIA;

        const projectEvaluation = evaluationGrades[projectCode];

        const totalScore = Object.entries(projectEvaluation).reduce((total, [key, value]) => {
            if (!key.includes("nota")) {
                return total;
            }

            return total + value;
        }, 0);

        const numberFormat = new Intl.NumberFormat("pt-BR", { maximumSignificantDigits: 2 });

        const scorePercentage = numberFormat.format((totalScore / maxScore) * 100);

        return scorePercentage;
    }

    /**
     * Get all projects with grades from a sheet and separates those already evaluated from those not evaluated.
     * @param {uuidStr:string} uuidStr - Use to search the sheet
     * @returns {Promise<{completedProjects:Array<Project>, availableProjects:Array<Project> }>}
     */
    const getSheetProjects = uuidStr => {
        return new Promise(async (resolve, reject) => {
            try {
                //First, we get the sheet grades
                const { data: grades } = await evaluationClient.getSheetGrades(uuidStr);

                const processedGrades = {};

                for (const [key, value] of Object.entries(grades)) {
                    processedGrades[key] = setStrGradesToNumber(value);
                }

                //Save the grades in context state.
                saveEvaluation(processedGrades);

                const completedProjects = [];
                const availableProjects = [];

                // separate the projects.
                evaluationSheet.projects.forEach(project => {
                    if (project.code in grades) {
                        completedProjects.push(project);
                    } else {
                        availableProjects.push(project);
                    }
                });

                resolve({
                    completedProjects,
                    availableProjects
                });
            }
            catch (err) {
                reject(err);
            }
        })
    }


    /**
     * Search in the sheets array the number of the sheet; 
     * @param {string} uuidStr 
     * @returns {number} 
     */
    const getSheetNumber = uuidStr => {
        const index = sheets.findIndex(({ uuid }) => uuid === uuidStr);

        return sheets.length - index;
    }

    const getTotalEvaluatedProject = () => {
        const total = Object.keys(evaluationGrades).length;

        return total;
    }

    return {
        getEvaluationSheet,
        evaluationSheet,
        getProjectByCode,
        saveEvaluation,
        evaluationGrades,
        calcProjectGrades,
        hasProjectEvaluated,
        getSheetProjects,
        getEvaluatorSheets,
        sheets,
        getSheetNumber,
        getTotalEvaluatedProject
    }
}

export {
    useEvaluationContext,
    EvaluationContext,
    EvaluationProvider
}