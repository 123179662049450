import { useState } from "react";

/**
 * It creates states and makes it possible to use them to manage the status of requests.
 * @param {*} initialState -> An object representing the states inside of the useRequestState hook; 
used to set the initial state of the states defined inside the hook.
 *  - isLoading: boolean
 *  - isRequestFailed: boolean
 * @returns 
 */
const useRequestState = (initialState) => {
    const [isLoading, setIsLoading] = useState(initialState?.isLoading || false);
    const [isRequestFailed, setIsRequestFailed] = useState(initialState?.isRequestFailed || false);

    return {
        isLoading, setIsLoading,
        isRequestFailed, setIsRequestFailed
    }
}

export default useRequestState;