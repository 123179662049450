import { useEvaluationContext } from "commom/contexts/useEvaluationContext";
import { useEffect, useState } from "react";
import { Alert, Card, Container, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { ListProjectsEvaluation } from "components/lists/evaluation";
import { toast } from "react-toastify";
import useRequestState from "hooks/useRequestState";
import { getEvaluationStatusText } from "commom/helpers/evaluation-status-text";
import style from "./evaluation-menu.module.css";
import getEvaluationStatusColor from "commom/helpers/evaluation-status-colors";
import { isEventOnline } from "commom/helpers/event-type";
import { EXPIRED, OPENED, EVALUATOR_FILLING } from "commom/consts/evaluations/const-status";
import useUuidStorage from "hooks/useUuidStorage";
import dayjs from "dayjs";

/**
 * Demonstrates all projects that the user needs to evaluate.
 * @returns The evaluation menu page
 */
const EvaluationMenu = () => {
    const { evaluationSheet, getSheetProjects, getSheetNumber, sheets } = useEvaluationContext();
    const [availableProjects, setAvailableProjects] = useState([]);
    const [completedProjects, setCompletedProjects] = useState([]);

    const {saveUuid} = useUuidStorage();

    const {
        isLoading,
        setIsLoading,
        isRequestFailed,
        setIsRequestFailed
    } = useRequestState({ isLoading: true });

    const { uuidStr } = useParams();

    useEffect(()=>{
        saveUuid(uuidStr);
    }, [])



    const getProjects = () => {
        setIsLoading(true);

        getSheetProjects(uuidStr)
            .then(({ completedProjects, availableProjects }) => {
                setCompletedProjects(completedProjects.reverse()); //Reverse to set the recent evaluation to the top.
                setAvailableProjects(availableProjects);

                setIsRequestFailed(false);
            })
            .catch(err => {
                console.log("Fail to list projects - ", err);

                toast.error('Ocorreu um erro e não foi possível listar os projetos.');

                setIsRequestFailed(true);
            })
            .finally(() => {
                setIsLoading(false);
            })

    }

    useEffect(getProjects, []);

    const evaluatedAnyProject = completedProjects.length > 0;

    const getEvaluationSheetStatus = () => {
        const sheet = sheets.find(({ uuid }) => uuidStr === uuid);

        return sheet?.status;
    }

    const status = getEvaluationSheetStatus();

    /**
     * Proccess the expiration datetime to extract and return the date and the time.
     * @returns {{date:string, hour:string}}
     */
    const getExpirationDatetime = () => {
        const datetime = dayjs(evaluationSheet.expiration_datetime);

        return {
            date: datetime.format('DD/MM/YYYY'),
            time: datetime.format("HH:mm")
        }
    }

    const expirationDatetime = getExpirationDatetime();

    return (
        <>
            <Container fluid as="section" className="py-3">
                {(!isLoading && !isRequestFailed) &&
                    <Card>
                        <Card.Header className="fw-bold">
                            Olá, <span>{evaluationSheet?.evaluator.nome}</span>
                        </Card.Header>
                        <Card.Body>
                            Ficha #{getSheetNumber(uuidStr)}
                            <p className="mt-1 mb-0">
                                Status:&nbsp;
                                <span className={`text-${getEvaluationStatusColor(status)} fw-bold`}>
                                    {getEvaluationStatusText(status)}
                                </span>
                            </p>
                            {
                                !isEventOnline &&
                                <span id='expiration_dates'>
                                {
                                    status === OPENED &&
                                    <p className="mb-0 fw-bold">
                                        Você poderá preencher essa ficha até {expirationDatetime.date} às {expirationDatetime.time}
                                    </p>
                                }
                                {
                                    (status === EXPIRED || status === EVALUATOR_FILLING) &&
                                    <p className="mb-0 fw-bold">
                                        Prazo de preenchimento encerrado em {expirationDatetime.date} às {expirationDatetime.time}
                                    </p>
                                }
                                </span>
                            }
                        </Card.Body>
                    </Card>
                }

                {
                    (!isLoading && !isRequestFailed) &&
                    <>
                        <section className="mt-3">

                            {/* {(evaluatedAnyProject && completedProjects.length < 5) && <Alert variant="success" className="mt-3">
                                <a href="#evaluationsCompleted" className="text-decoration-none ">Projetos avaliados</a>
                            </Alert>
                            } */}

                            {availableProjects.length > 0 &&
                                <>
                                    {status === OPENED &&
                                        <Alert variant="info" className="mt-3" data-testid="total_available_projects">
                                            Você tem <b>{availableProjects.length}</b> projeto(s) para avaliar
                                        </Alert>
                                    }


                                    <ListProjectsEvaluation
                                        projects={availableProjects}
                                        uuidStr={uuidStr}
                                    />
                                </>

                            }
                        </section>

                        {evaluatedAnyProject && <section className="mt-4" id="evaluationsCompleted">
                            <div className="h5 p-3 mb-3 bg-success bg-opacity-10 border border-success border-start-0 rounded-end">
                                Projetos avaliados
                            </div>

                            <ListProjectsEvaluation
                                projects={completedProjects}
                                uuidStr={uuidStr}
                            />
                        </section>}
                    </>
                }

                {
                    (!isLoading && isRequestFailed) &&
                    <Alert variant="danger">
                        Ocorreu um erro e não foi possível listar os projetos.
                        <button
                            className={`btn-link p-0 ${style.tryAgainBtn}`}
                            onClick={getProjects}>
                            Pressione aqui
                        </button>
                        &nbsp;para tentar novamente
                    </Alert>
                }

                {
                    isLoading &&
                    <div className="text-center">
                        <Spinner role="status" />
                        <p className="smilage fs-5">
                            Aguarde mais um pouco...
                        </p>
                    </div>
                }
            </Container>



        </>
    )
}

export default EvaluationMenu;
