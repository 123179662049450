import useRequestState from "hooks/useRequestState";
import { ENDPOINT } from "lib/axios";
import { useEffect, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import loginClient from "services/client/login-client";
import style from "./login-form.module.css";

/**
 * The login form render the codigo and captcha fields to the user. 
 * The submit function is not implemented here, but it's possible to pass the submit function
 * via props.
 * @param {*} {onSubmit} 
 *  -   onLoginSuccess -> Called when the form is submited and the request to server is successful
 *  -   onLoginError -> Called when the form is submited and the request to server isn't successful
 * @returns The login form (JSX Element)
 */
const LoginForm = ({ onLoginSuccess, onLoginError }) => {
    const [captcha, setCaptcha] = useState();
    const [captchaImgLoaded, setCaptachImgLoaded] = useState(false);
    const { register, formState: { errors }, handleSubmit } = useForm();
    

    const {
        isLoading,
        setIsLoading,
        isRequestFailed,
        setIsRequestFailed
    } = useRequestState({ isLoading: true });

    const getCaptcha = () => {
        setIsLoading(true);
        setCaptachImgLoaded(false);

        loginClient.getCaptcha()
            .then(response => {
                setCaptcha(response.data);

                //We set loading to false when the captcha image is loaded; Look for the onLoad method in captcha img tag.
                setIsRequestFailed(false);
            })
            .catch(err => {
                console.log("Login error - ", err);
                setIsRequestFailed(true);
                setCaptachImgLoaded(true); // We set the captcha img is loaded to stop loading animation.
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    useEffect(getCaptcha, []);

    const onLoginSubmit = async data => {
        //get the captcha key from state instead the input hidden
        data['captcha_0'] = captcha.key;

        //set the captcha to lower case.
        const captchaDigits = data['captcha_1'];
        data['captcha_1'] = captchaDigits.toLowerCase();

        try {
            setIsLoading(true);

            const response = await loginClient.validateLogin(data);

            const uuid = response.data['uuid_str'];

            const successData = {
                data,
                uuidStr: uuid
            }

            

            onLoginSuccess && onLoginSuccess(successData);
        }
        catch (err) {
            getCaptcha();
            onLoginError && onLoginError(err);
        }
        finally {
            setIsLoading(false);
        }
    }

    return (
        <Form onSubmit={handleSubmit(onLoginSubmit)} className={`w-100 ${style.login_form}`}>
            <Form.Group className="mb-3" controlId="codigo">
                <Form.Label>Código</Form.Label>
                <Form.Control
                    {...register('codigo', {
                        disabled: isLoading,
                        required: {
                            value: true,
                            message: 'Por favor, informe o seu código de avaliação'
                        },
                        maxLength: {
                            value: 6,
                            message: 'O código de avaliação deve ter no máximo 6 letras'
                        },
                        minLength: {
                            value: 6,
                            message: 'O código de avaliação precisa ter no mínimo 6 letras'
                        }
                    })}
                    maxLength={6}
                    type="text"
                />
                {
                    errors.codigo &&
                    <span
                        role="alert"
                        className="text-danger d-block mt-2">
                        {errors.codigo.message}
                    </span>
                }
            </Form.Group>


            <Form.Group className="mb-3" controlId="captcha">
                <Form.Label>
                    Digite as letras que aparecem na imagem
                </Form.Label>

                <Form.Control
                    {...register('captcha_1', {
                        disabled: isLoading,
                        required: {
                            value: true,
                            message: 'Por favor, informe o CAPTCHA'
                        },
                    })}
                    type="text" />
                {
                    errors.captcha_1 &&
                    <span
                        role="alert"
                        className="text-danger d-block mt-2">
                        {errors.captcha_1.message}
                    </span>
                }

                {captcha && <div className={`text-center ${captchaImgLoaded ? 'd-block' : 'd-none'}`}>
                    <img
                        onLoad={() => setCaptachImgLoaded(true)}
                        className="mt-2 w-100 rounded mx-auto d-block"
                        style={{ maxWidth: 150 }}
                        src={`${ENDPOINT}${captcha?.image_url}`}
                        alt="Captcha" />

                </div>}
            </Form.Group>

            {(!isLoading && !isRequestFailed) && <Button
                className="mt-3 w-100"
                type="submit"
                disabled={isLoading}
                variant="lilac">
                Acessar
            </Button>}

            {(isLoading || !captchaImgLoaded) &&
                <div className="d-flex justify-content-center align-items-center">
                    <Spinner animation="border" variant="light" role="status" />
                </div>
            }

            {(!isLoading && isRequestFailed) &&
                <div className="text-center" role="alert">
                    <span>
                        Ocorreu um erro e não foi possível carregar o captcha.
                    </span>
                    <Button
                        onClick={getCaptcha}
                        className="mt-3 w-100"
                        type="submit"
                        variant="lilac">
                        Clique aqui para tentar carregar o captcha novamente.
                    </Button>
                    <hr />
                    <span>
                        Ou entre em contato com a equipe FEBRACE.
                    </span>
                </div>
            }
        </Form>
    )
}

export default LoginForm;