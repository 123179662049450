import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";

import { Container, Navbar, Offcanvas, ListGroup } from "react-bootstrap";
import { useEffect, useState } from "react";
import { getEvaluationStatusText } from "commom/helpers/evaluation-status-text";
import { isEventOnline } from "commom/helpers/event-type";
import { IoMdExit } from "react-icons/io"
import useUuidStorage from "hooks/useUuidStorage";


/**
 * @typedef {import("commom/contexts/useEvaluationContext").Sheet} Sheet
 */


export const APPROXIMATE_HEIGHT = 60;

/** 
 * @typedef EvaluationNavbarProps
 * 
 * @property {Array<Sheet>} sheets
 * 
 * @param {EvaluationNavbarProps} 
 * @returns 
 */
const EvaluationNavbar = ({ sheets = [] }) => {
    const { uuidStr } = useParams();
    const [isShowOffcanvas, setIsShowOffcanvas] = useState(false);

    const location = useLocation();

    const isOnMenu = location.pathname.includes("menu");

    const handleShowOffcanvas = () => setIsShowOffcanvas(true);

    const handleCloseOffcanvas = () => setIsShowOffcanvas(false);

    const ONLINE_EVAL_AREA_URL = process.env.REACT_APP_API_URL + '/avaliadores/avaliacao_online/';



    /**
     * Close the offcanvas when page is changed
     */
    useEffect(handleCloseOffcanvas, [location]);

    const navigate = useNavigate();
    const { removeUuid } = useUuidStorage();

    const onSheetClick = (uuid) => {        

        return navigate(`/avaliacao/${uuid}/menu`);
    }

    /**
     * Sort the list of sheets so that the most recent is the first in the list and the oldest is the last.
     * @returns {JSX.Element} A list of sheets
     */
    const listSheets = () => {

        const sheetsElements = sheets.map((sheet, index) => {
            const isSelected = uuidStr === sheet.uuid;

            return (
                <ListGroup.Item
                    role="button"
                    onClick={() => onSheetClick(sheet.uuid)}
                    key={sheet.uuid}
                    className={`list-group-item-action ${isSelected ? 'active' : ''}`}
                    data-testid={`Ficha #${sheets.length - index}`}
                >
                    <div className="d-flex justify-content-between">
                        <span>
                            {`Ficha #${sheets.length - index}`}
                        </span>
                        <span>
                            {/*TODO: the maximum value of projects is fixed, maybe it would be interesting to leave it dynamic. */}
                            {`${sheet.total_evaluated_projects}/5`}
                        </span>
                    </div>
                    <small className={`${!isSelected ? "text-muted fw-bold" : ""} d-block`}>
                        {getEvaluationStatusText(sheet?.status)}
                    </small>

                    {/* {project.code} */}
                </ListGroup.Item>
            )
        });

        // Reversing the array, the most recent ones will be shown first
        return sheetsElements;
    }

    const onExitClick = () => {
        removeUuid();

        const today = new Date();

        // On January the exit function should redirect the user back to
        // the judge area on Minha FEBRACE
        //
        // On March the user should be redirected to the FEBRACEm first page
        // (code/captcha form)
        if (isEventOnline)
            return window.location.replace(ONLINE_EVAL_AREA_URL);
        else
            return navigate(`/`, { replace: true });
    }

    return (
        <Navbar bg="purple" variant="dark" expand={false} fixed="top">
            <Container fluid>
                <Link
                    className="navbar-brand smilage"
                    to={(isEventOnline && isOnMenu)? ONLINE_EVAL_AREA_URL : `/avaliacao/${uuidStr}/menu`}>
                    <h1 className="m-0 text-white d-flex justify-content-center">
                        {/* the back button must not be shown in the menu. */}
                        {!isOnMenu && <><IoArrowBack role="img" aria-label="Botão para voltar à página anterior" />&nbsp;</>} FEBRACE
                    </h1>
                </Link>

                <Navbar.Toggle
                    className="text-white"
                    onClick={handleShowOffcanvas}
                    aria-controls="offcanvas-evaluation" />

                <Navbar.Offcanvas
                    show={isShowOffcanvas}
                    id="offcanvas-evaluation"
                    aria-labelledby="offcanvas-evaluation"
                    placement="end"
                >
                    <Offcanvas.Header closeButton onHide={handleCloseOffcanvas}>
                        <Offcanvas.Title id="offcanvas-evaluation">
                            <h1 className="m-0 smilage">
                                FEBRACE
                            </h1>
                            <small className="text-muted">
                                Avaliações
                            </small>
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className="p-0">
                        <ListGroup className="rounded-0" role="list">
                            <ListGroup.Item>
                                <span
                                    role="button"
                                    onClick={onExitClick}
                                    className="text-decoration-none text-dark d-block">
                                    <IoMdExit />&nbsp;Sair
                                </span>
                            </ListGroup.Item>
                            {/* <ListGroup.Item
                                role="listitem"
                                as={Link}
                                className={`list-group-item-action ${isOnMenu ? 'active' : ''}`}
                                to={`/avaliacao/${uuidStr}/menu`}>
                                Início
                            </ListGroup.Item> */}
                            {
                                listSheets()
                            }
                            {/* <ListGroup.Item
                                className='list-group-item-action'
                                action={true}
                                to="#enviar">
                                Enviar
                            </ListGroup.Item> */}
                        </ListGroup>
                    </Offcanvas.Body>

                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    )
}

export default EvaluationNavbar;
