import style from "./highlight.module.css";
import FebraceMap from "assets/images/mapa.png"
import { useState } from "react";
import { Spinner } from "react-bootstrap";

const HighlightImage = (props) => {
    const [isImgLoaded, setIsImgLoaded] = useState(false);

    const onImgLoad = () => {
        setIsImgLoaded(true);
        props.onImgLoad && props.onImgLoad();
    }

    const overlayPositions = {
        top: props.top ? `${props.top}%` : undefined,
        left: props.left ? `${props.left}%` : undefined,
    };

    return (
        <div style={{ position: 'relative' }}>
            <img
                src={FebraceMap}
                className={`w-100 ${isImgLoaded ? 'd-block' : 'd-none'}`}
                onLoad={onImgLoad}
                alt="Highlight"
            />
            {!isImgLoaded &&
                <div className="d-flex justify-content-center align-items-center">
                    <Spinner animation="grow" />
                    <p className="m-0">
                        &nbsp;Carregando mapa...
                    </p>
                </div>
            }
            <div
                role="alert"
                aria-label="Localização do estande"
                style={overlayPositions}
                className={style.overlayStyle}
                id="highlight" />


        </div >
    )
}

export default HighlightImage;