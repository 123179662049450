import React from "react";

/**
 * Represents an input radio with a square shape.
 * @param {*} {
    children,
    id,
    value,
    isSelected = false,
    ...rest
}
    - children<JSX.Element>: Component child element
    - id<string>: The element id;
    - value<any>: The input value;
    - isSelected<boolean>: Indicates whether the radio is selected. If selected, the input will be green, otherwise purple.
 * @returns 
 */
const SquareRadioButton = React.forwardRef(({
    children,
    id,
    value,
    isSelected = false,
    disabled,
    ...rest
}, ref) => (
    <label
        className={`flex-fill p-2 border position-relative text-center ${isSelected ? 'bg-green' : 'bg-purple'} ${disabled ? 'opacity-50' : 'opacity-100'}`}
        role={"button"}
        htmlFor={id}>

        <input
            className="invisible w-0 h-0 position-absolute"
            type="radio"
            id={id}
            value={value}
            disabled={disabled}
            {...rest}
            ref={ref}
        />

        {children}
    </label>
))

export default SquareRadioButton;