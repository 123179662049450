import { useEvaluationContext } from "commom/contexts/useEvaluationContext";
import FullscreenLoading from "components/loading/fullscreen";
import EvaluationNavbar from "components/navbar/evaluation";
import { APPROXIMATE_HEIGHT } from "components/navbar/evaluation/evaluation-navbar";
import useRequestState from "hooks/useRequestState";
import useUuidStorage from "hooks/useUuidStorage";
import { useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

/**
 * Base page for evaluationa actions. The `<Outlet>` will renderer the child routes
 * that were defined in the route settings.
 * @returns 
 */
const EvaluationBase = () => {
    const { uuidStr } = useParams();
    const { getEvaluationSheet, sheets, getEvaluatorSheets } = useEvaluationContext();
    const { removeUuid } = useUuidStorage();

    const {
        isLoading,
        setIsLoading
    } = useRequestState({ isLoading: true });

    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true);

        getEvaluationSheet(uuidStr)
            .then(({ evaluator }) => {
                if (sheets.length > 0) {
                    return;
                }
                
                getEvaluatorSheets(evaluator.avaliador_uuid);
            })
            .catch(err => {
                console.log("Fail to evaluate - ", err);

                toast.error('Não foi possível carregar as informações da sua ficha. Por favor, efetue a autenticação informando seu código novamente ou entre em contato com alguém da equipe FEBRACE.');

                // After the error has be catched, we remove the uuid from localstorage.
                removeUuid();

                navigate('/');
            })
            .finally(() => {
                setIsLoading(false);
            })
    }, [uuidStr]);

    return (
        <>
            {!isLoading ?
                <>
                    <header>
                        <EvaluationNavbar
                            sheets={sheets}
                        />
                    </header>
                    <main style={{ marginTop: APPROXIMATE_HEIGHT + 10 }}> {/* Apply a margin between the navbar and the main content */}
                        <Outlet />
                    </main>
                </>
                :
                <FullscreenLoading>
                    <h1 className="smilage">
                        Aguarde! Estamos preparando sua ficha de avaliação.
                    </h1>
                </FullscreenLoading>
            }
        </>
    )
}

export default EvaluationBase;