import { OPENED } from "commom/consts/evaluations/const-status";
import { useEvaluationContext } from "commom/contexts/useEvaluationContext";
import EvaluationForm from "components/forms/evaluation-form";
import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useParams, Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import evaluationClient from "services/client/evaluation-client";

/**
 * Page for evaluating a project.
 * @returns The page with evaluate form
 */
const Evaluate = () => {

    const { projectCode, uuidStr } = useParams();
    const navigate = useNavigate();

    const { getProjectByCode, saveEvaluation, evaluationGrades, sheets, getEvaluatorSheets, evaluationSheet } = useEvaluationContext();

    const project = getProjectByCode(projectCode);

    const projectDisplay = (project.box && `do estande ${project.box}`) || project.code

    useEffect(() => {
        if (!project) {
            toast.error("Projeto não encontrado! Por favor, selecione novamente.");
        }
    }, []);


    const onSuccessSubmit = async evaluationData => {

        try {            
            await evaluationClient.sendEvaluationGrades(uuidStr, project.id, evaluationData);

            await getEvaluatorSheets(evaluationSheet.evaluator.avaliador_uuid);
            
            saveEvaluation({
                [project.code]: {
                    ...evaluationData
                }
            }); //Save the evaluation in the context state.

            toast.success(`Você avaliou com sucesso o projeto ${projectDisplay}.`);

            navigate(`/avaliacao/${uuidStr}/menu`, { replace: true }); //Go back to the menu.
        }
        catch (err) {
            console.error(err);
            toast.error("Ops... Ocorreu um erro ao processar as notas e comentários. Por favor, verifique o formulário ou entre em contato com a equipe FEBRACE.");
        }
    }

    if (!project) {
        return <Navigate
            to={`/avaliacao/${uuidStr}/menu`}
            replace={true}
        />
    }

    const getFormDefaultValues = () => {
        if (!(projectCode in evaluationGrades)) {
            return {}
        }

        return evaluationGrades[projectCode];
    }

    const sheet = sheets.find((sheet) => sheet.uuid === uuidStr);

    const canEdit = sheet?.status === OPENED;

    return (
        <Container as="section">

            <EvaluationForm
                sheet={sheet}
                project={project}
                onSuccessSubmit={onSuccessSubmit}
                disabledFields={
                    canEdit ? [] : [
                        "nota_planejamento",
                        "nota_execucao",
                        "nota_inovacao",
                        "nota_atitude",
                        "nota_criatividade",
                        "nota_apresentacao",
                        "nota_relatorio",
                        "comentario",
                    ]
                }
                defaultValues={{
                    ...getFormDefaultValues()
                }}
            />
        </Container>
    )
}

export default Evaluate;