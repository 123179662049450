import style from "./fullscreen-loading.module.css";
import { Spinner } from "react-bootstrap";

const FullscreenLoading = ({ children }) => {
    return (
        <section className={`bg-dark-blue d-flex justify-content-center align-items-center ${style.fullscreenLoadingContainer}`}>
            <div className="p-5 text-center" role="status">
                {/* <img src={FebraceIconLogo} className={`w-100 h-100 ${style.fullscreenLoadingImage}`} /> */}
                <Spinner variant="light" />
                {children}
            </div>
        </section>

    )
}

export default FullscreenLoading;