import { useRouteError } from "react-router-dom";

/**
 * An error page to notify the user when an unexpected error occurs 
 * @returns {JSX.Element} 
 */
const ErrorPage = () => {
    const error = useRouteError();

    console.log(error);

    return (
        <div className="d-flex h-100 flex-column justify-content-center align-items-center fs-5 bg-dark-blue smilage p-3">
            <h1>Oops!</h1>
            <p>Desculpe, um erro inesperado ocorreu. Por favor, entre em contato com a equipe FEBRACE.</p>
            <p>
                <i>{error.statusText || error.message}</i>
            </p>
        </div>
    );
}

export default ErrorPage;