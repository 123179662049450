import {
    OPENED,
    EVALUATOR_FILLING,
    EXPIRED,
    FILL_TEAM
} from "commom/consts/evaluations/const-status";

const statusColors = new Map();

// We're use bootstrap colors
statusColors.set(OPENED, "info");
statusColors.set(EVALUATOR_FILLING, "success");
statusColors.set(EXPIRED, "danger");
statusColors.set(FILL_TEAM, "success");

const getEvaluationStatusColor = status => statusColors.get(status);

export default getEvaluationStatusColor;
