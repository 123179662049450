import { EvaluationProvider } from "commom/contexts/useEvaluationContext";
import ErrorPage from "pages/error-page";
import Evaluate from "pages/evaluation/evaluate";
import EvaluationBase from "pages/evaluation/evaluation-base";
import EvaluationMenu from "pages/evaluation/menu";
import Login from "pages/login";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

/**
 * Routes configuration
 */
const router = createBrowserRouter([
    {
        path: "/",
        element: <Login />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/avaliacao/:uuidStr",
        element: (
            <EvaluationProvider>
                <EvaluationBase />
            </EvaluationProvider>
        ),
        errorElement: <ErrorPage />,
        children: [
            {
                path: "/avaliacao/:uuidStr/menu",
                element: <EvaluationMenu />,
            },
            {
                path: "/avaliacao/:uuidStr/avaliar/:projectCode",
                element: <Evaluate />,
            }
        ]
    }
]);

const Routes = () => {
    return (
        <RouterProvider router={router} />
    )
}


export default Routes;