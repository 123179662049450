import axios from "axios";

export const ENDPOINT = process.env.REACT_APP_API_URL;

const client = () => {
    const http = axios.create({
        baseURL: ENDPOINT,
        headers: {
            'Accept': 'application/json',
            'Content-type': 'application/json',
        },
        timeout: 5000 //5 seconds
    });

    return http;
}

const api = client();

export default api;