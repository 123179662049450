import api from "lib/axios";

const BASE_URL = 'avaliadores';

const loginClient = {
    /**
     * Get the captcha image url and the key
     * @returns an object:
     *  - imagem_url: The captcha image url
     *  - chave: the captcha key.
     */
    getCaptcha: () => api.get(`captcha/refresh/`, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest'
        }
    }),

    /**
     * Verify if user can start the avaliation
     * @param {*} data - An object
     *  - codigo: The avaliation code;
     *  - captcha_1: The captcha inputed by the user;
     *  - captcha_0: The captcha key (chave).
     * @returns 
     */
    validateLogin: (data) => api.post(`${BASE_URL}/iniciar_avaliacao_mobile/`, data, {
        headers: {
            "Content-Type": 'application/x-www-form-urlencoded'
        }
    })
}

export default loginClient;