/**
 * Obtains the information regarding the evaluation criteria.
 * @param {*} {projectCode} 
 *  - projectCode: 
Project code; is used to check whether it is an engineering design or not in order to be able to adapt the criteria descriptions.
 * @returns An object containing the information for all the criteria; Returned object structure: 
        {title: string, descriptionItems: Array<string>}
 */
const getCriteriaHelpTexts = ({ projectCode }) => {
    const criteria = {
        scientificAttitude: {
            title: "Atitude científica",
            descriptionItems: [
                "Acredita no projeto, demonstra entusiasmo e determinação para superar as dificuldades do projeto",
                "Demonstra competência para analisar criticamente dados e informações",
                "Compreende diferentes pontos de vista, sabe distinguir e compreender situações novas",
                "É capaz de formular considerações sobre a experiência realizada e compará-la com experiências similares",
                "Utilizou equipamentos, técnicas de laboratório ou sistemas computacionais adequadamente",
                "Foi capaz de engajar pessoas da sua família, escola ou comunidade no projeto"
            ]
        },
        creativity: {
            title: "Criatividade",
            descriptionItems: [
                "Inovou na abordagem (recursos, equipamentos, método) da pesquisa",
                "Relacionou informações de maneira original para superar as dificuldades do projeto",
                "Improvisou materiais ou equipamentos para alcançar o resultado final"
            ]
        },
        oralPresentation: {
            title: "Apresentação Oral",
            descriptionItems: [
                "Organizou as informações relevantes sobre o desenvolvimento e resultados do projeto",
                "Domina o assunto do projeto",
                "Consegue expressar suas ideias de forma objetiva e sintética",
                "Entende quais são os limites de seu projeto"
            ]
        },
        documentation: {
            title: "Documentação",
            descriptionItems: [
                {
                    logbook: { //Diário de bordo
                        title: "Diário de bordo",
                        descriptionItems: [
                            "Organizado em ordem cronológica",
                            "Descreve os procedimentos adotados e as observações",
                            "Demonstra rigor no registro de experimentos, testes, medidas e observações"
                        ]
                    },
                    poster: {
                        title: "Pôster",
                        descriptionItems: [
                            "Apresenta de forma sucinta os objetivos, o desenvolvimento, os resultados e as conclusões do projeto",
                            "Dados mais relevantes da pesquisa foram sintetizados",
                            "As informações estão organizadas de forma coerente e atrativa"
                        ]
                    },
                    articleReport: {
                        title: "Artigo / Relatório",
                        descriptionItems: [
                            "Está bem estruturado: Resumo; Lista de Tabelas/Figuras; Sumário; Introdução; Materiais e métodos; Fundamentação teórica; Resultado e Análise dos dados; Discussão; Conclusões; Referências Bibliográficas; Anexos e Apêndices (quando houver)"
                        ]
                    }
                }

            ]
        }
    };

    const isEngProject = projectCode.includes("ENG");

    // Engineering projects have specific descriptions.
    if (isEngProject) {
        const engCriteria = {
            planning: {
                title: "Planejamento",
                descriptionItems: [
                    "O problema está descrito de forma clara e objetiva",
                    "Foram levantadas alternativas de soluções",
                    "A solução proposta foi relacionada a outras soluções",
                    "A solução proposta é adequada ao problema",
                    "Foram planejadas etapas necessárias para o desenvolvimento do protótipo / modelo (desenho de solução, protótipo)",
                    "Foram definidos os materiais necessários",
                    "Foram estabelecidos um cronograma e um orçamento"
                ]
            },
            execution: {
                title: "Execução",
                descriptionItems: [
                    "Simulou, prototipou, construíu e testou o protótipo em várias condições / ensaios",
                    "Utilizou métodos matemáticos e estatísticos para análise e interpretação dos testes",
                    "O protótipo demonstra habilidade e viabilidade econômica",
                    "O aluno consegue vislumbrar possibilidades de continuidade de seu projeto"
                ]
            },
            innovation: {
                title: "Inovação",
                descriptionItems: [
                    "Apresenta uma solução criativa ao problema identificado (projeto de engenharia)",
                    "A proposta tem relevância social e potencial para transformar a realidade da comunidade em que o aluno vive"
                ]
            }
        }

        // add the eng criteria to criteria obj.
        Object.assign(criteria, engCriteria);
    }
    else {
        const otherCriteria = {
            planning: {
                title: "Planejamento",
                descriptionItems: [
                    "A questão e a hipótese estão claras e bem definidas",
                    "Os objetivos são claros e relacionados com pesquisas similares",
                    "A justificativa está embasada em dados científicos e pesquisa bibliográfica",
                    "Foram escolhidos e planejados métodos de coleta, registro e análise de dados",
                    "As variáveis para serem estudadas foram definidas",
                    "Foi estabelecido um cronograma",
                ]
            },
            execution: {
                title: "Execução",
                descriptionItems: [
                    "Realizou a coleta e registro de dados com rigor",
                    "Coletou dados suficientes e consistentes para sustentar interpretações e conclusões, e a reprodutibilidade de resultados",
                    "Utilizou conceitos de matemática e estatística para analisar os dados",
                    "A conclusão foi relacionada com a proposta ou a pergunta do início do trabalho"
                ]
            },
            innovation: {
                title: "Inovação",
                descriptionItems: [
                    "Apresenta uma resposta original à questão levantada (pesquisa científica)",
                    "A proposta tem relevância social e potencial para transformar a realidade da comunidade em que o aluno vive"
                ]
            }
        }

        // add the other criteria to criteria obj.
        Object.assign(criteria, otherCriteria);
    }

    return criteria;
}


export default getCriteriaHelpTexts;
