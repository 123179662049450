const coords = [
    { 'estande': '1', 'X_percentage': '55.9614963902866', 'Y_percentage': '74.6007438197331' },
    { 'estande': '2', 'X_percentage': '54.4191642966528', 'Y_percentage': '74.6116823452199' },
    { 'estande': '3', 'X_percentage': '53.4128199518705', 'Y_percentage': '74.6335593961934' },
    { 'estande': '4', 'X_percentage': '51.0500984467294', 'Y_percentage': '74.6116823452199' },
    { 'estande': '5', 'X_percentage': '49.4749507766353', 'Y_percentage': '74.5898052942463' },
    { 'estande': '6', 'X_percentage': '48.5561146357471', 'Y_percentage': '74.5898052942463' },
    { 'estande': '7', 'X_percentage': '46.980966965653', 'Y_percentage': '74.5898052942463' },
    { 'estande': '8', 'X_percentage': '45.9527455698972', 'Y_percentage': '74.5898052942463' },
    { 'estande': '9', 'X_percentage': '43.2399912491796', 'Y_percentage': '74.6335593961934' },
    { 'estande': '10', 'X_percentage': '41.6867206300591', 'Y_percentage': '74.6116823452199' },
    { 'estande': '11', 'X_percentage': '40.6803762852768', 'Y_percentage': '74.6116823452199' },
    { 'estande': '12', 'X_percentage': '39.2146138700503', 'Y_percentage': '74.5679282432728' },
    { 'estande': '13', 'X_percentage': '38.1426383723474', 'Y_percentage': '74.6116823452199' },
    { 'estande': '14', 'X_percentage': '35.3423758477357', 'Y_percentage': '74.6116823452199' },
    { 'estande': '15', 'X_percentage': '33.7891052286152', 'Y_percentage': '74.6116823452199' },
    { 'estande': '16', 'X_percentage': '32.8046379348064', 'Y_percentage': '74.6116823452199' },
    { 'estande': '17', 'X_percentage': '31.2513673156858', 'Y_percentage': '74.6116823452199' },
    { 'estande': '18', 'X_percentage': '30.2450229709035', 'Y_percentage': '74.6335593961934' },
    { 'estande': '19', 'X_percentage': '27.5541457011595', 'Y_percentage': '74.6335593961934' },
    { 'estande': '20', 'X_percentage': '26.0227521330125', 'Y_percentage': '74.6335593961934' },
    { 'estande': '21', 'X_percentage': '24.9945307372566', 'Y_percentage': '74.6335593961934' },
    { 'estande': '22', 'X_percentage': '23.4412601181361', 'Y_percentage': '74.6116823452199' },
    { 'estande': '23', 'X_percentage': '22.4349157733538', 'Y_percentage': '74.6116823452199' },
    { 'estande': '24', 'X_percentage': '19.7659155545832', 'Y_percentage': '74.6335593961934' },
    { 'estande': '25', 'X_percentage': '18.2126449354627', 'Y_percentage': '74.6116823452199' },
    { 'estande': '26', 'X_percentage': '17.1844235397068', 'Y_percentage': '74.6335593961934' },
    { 'estande': '27', 'X_percentage': '15.6749070225334', 'Y_percentage': '74.6554364471669' },
    { 'estande': '28', 'X_percentage': '14.624808575804', 'Y_percentage': '74.6116823452199' },
    { 'estande': '29', 'X_percentage': '11.6714066943776', 'Y_percentage': '74.6262743382192' },
    { 'estande': '30', 'X_percentage': '10.6213082476482', 'Y_percentage': '74.5970903522205' },
    { 'estande': '31', 'X_percentage': '9.10450667250055', 'Y_percentage': '74.6262743382192' },
    { 'estande': '32', 'X_percentage': '8.06897834171954', 'Y_percentage': '74.5825202362722' },
    { 'estande': '33', 'X_percentage': '8.65237366003063', 'Y_percentage': '71.3447166921899' },
    { 'estande': '34', 'X_percentage': '9.70247210676001', 'Y_percentage': '71.3738788011376' },
    { 'estande': '35', 'X_percentage': '11.2630277838547', 'Y_percentage': '71.3592868081383' },
    { 'estande': '36', 'X_percentage': '12.2402100196893', 'Y_percentage': '71.3738788011376' },
    { 'estande': '37', 'X_percentage': '13.7861955808357', 'Y_percentage': '71.3447166921899' },
    { 'estande': '38', 'X_percentage': '14.8071100415664', 'Y_percentage': '71.3301246991906' },
    { 'estande': '39', 'X_percentage': '16.3676875957121', 'Y_percentage': '71.3738788011376' },
    { 'estande': '40', 'X_percentage': '17.4031940494421', 'Y_percentage': '71.3738788011376' },
    { 'estande': '41', 'X_percentage': '19.4742288339532', 'Y_percentage': '71.3738788011376' },
    { 'estande': '42', 'X_percentage': '20.4659811857362', 'Y_percentage': '71.3738788011376' },
    { 'estande': '43', 'X_percentage': '21.990089695909', 'Y_percentage': '71.3520017501641' },
    { 'estande': '44', 'X_percentage': '23.0401881426384', 'Y_percentage': '71.3520017501641' },
    { 'estande': '45', 'X_percentage': '24.5715817107854', 'Y_percentage': '71.3665937431634' },
    { 'estande': '46', 'X_percentage': '25.548742069569', 'Y_percentage': '71.3811638591118' },
    { 'estande': '47', 'X_percentage': '27.1384817326624', 'Y_percentage': '71.3374097571647' },
    { 'estande': '48', 'X_percentage': '28.1739881863925', 'Y_percentage': '71.3520017501641' },
    { 'estande': '49', 'X_percentage': '29.683504703566', 'Y_percentage': '71.3447166921899' },
    { 'estande': '50', 'X_percentage': '30.7044410413476', 'Y_percentage': '71.3738788011376' },
    { 'estande': '51', 'X_percentage': '32.2649967184424', 'Y_percentage': '71.3738788011376' },
    { 'estande': '52', 'X_percentage': '33.285933056224', 'Y_percentage': '71.3592868081383' },
    { 'estande': '53', 'X_percentage': '34.817326624371', 'Y_percentage': '71.3520017501641' },
    { 'estande': '54', 'X_percentage': '35.8236709691534', 'Y_percentage': '71.3520017501641' },
    { 'estande': '55', 'X_percentage': '37.3769415882739', 'Y_percentage': '71.3520017501641' },
    { 'estande': '56', 'X_percentage': '38.3832859330562', 'Y_percentage': '71.3957558521111' },
    { 'estande': '57', 'X_percentage': '39.9146795012032', 'Y_percentage': '71.3520017501641' },
    { 'estande': '58', 'X_percentage': '40.9647779479326', 'Y_percentage': '71.3520017501641' },
    { 'estande': '59', 'X_percentage': '42.4961715160796', 'Y_percentage': '71.3520017501641' },
    { 'estande': '60', 'X_percentage': '43.502515860862', 'Y_percentage': '71.3520017501641' },
    { 'estande': '61', 'X_percentage': '45.0485014220083', 'Y_percentage': '71.3520017501641' },
    { 'estande': '62', 'X_percentage': '46.0985998687377', 'Y_percentage': '71.3520017501641' },
    { 'estande': '63', 'X_percentage': '47.6737475388318', 'Y_percentage': '71.3665937431634' },
    { 'estande': '64', 'X_percentage': '48.6509078976154', 'Y_percentage': '71.3520017501641' },
    { 'estande': '65', 'X_percentage': '50.1968934587618', 'Y_percentage': '71.3228396412164' },
    { 'estande': '66', 'X_percentage': '51.2323999124918', 'Y_percentage': '71.3520017501641' },
    { 'estande': '67', 'X_percentage': '52.7127543207176', 'Y_percentage': '71.3957558521111' },
    { 'estande': '68', 'X_percentage': '53.7409757164734', 'Y_percentage': '71.3738788011376' },
    { 'estande': '69', 'X_percentage': '55.294246335594', 'Y_percentage': '71.3520017501641' },
    { 'estande': '70', 'X_percentage': '56.3224677313498', 'Y_percentage': '71.3520017501641' },
    { 'estande': '71', 'X_percentage': '57.8319842485233', 'Y_percentage': '71.3301246991906' },
    { 'estande': '72', 'X_percentage': '57.8538612994968', 'Y_percentage': '69.6018376722818' },
    { 'estande': '73', 'X_percentage': '56.3443447823233', 'Y_percentage': '69.6018376722818' },
    { 'estande': '74', 'X_percentage': '55.294246335594', 'Y_percentage': '69.6018376722818' },
    { 'estande': '75', 'X_percentage': '53.762852767447', 'Y_percentage': '69.6018376722818' },
    { 'estande': '76', 'X_percentage': '52.7346313716911', 'Y_percentage': '69.5799606213082' },
    { 'estande': '77', 'X_percentage': '51.2251148545176', 'Y_percentage': '69.5799606213082' },
    { 'estande': '78', 'X_percentage': '50.1968934587618', 'Y_percentage': '69.5799606213082' },
    { 'estande': '79', 'X_percentage': '48.6436228396412', 'Y_percentage': '69.6018376722818' },
    { 'estande': '80', 'X_percentage': '47.6154014438854', 'Y_percentage': '69.5799606213082' },
    { 'estande': '81', 'X_percentage': '46.0402537737913', 'Y_percentage': '69.5799606213082' },
    { 'estande': '82', 'X_percentage': '45.0557864799825', 'Y_percentage': '69.5799606213082' },
    { 'estande': '83', 'X_percentage': '28.1448260774448', 'Y_percentage': '69.5580835703347' },
    { 'estande': '84', 'X_percentage': '27.1530737256618', 'Y_percentage': '69.5872456792824' },
    { 'estande': '85', 'X_percentage': '25.548742069569', 'Y_percentage': '69.5872456792824' },
    { 'estande': '86', 'X_percentage': '24.5861518267338', 'Y_percentage': '69.5580835703347' },
    { 'estande': '87', 'X_percentage': '23.025596149639', 'Y_percentage': '69.5799606213082' },
    { 'estande': '88', 'X_percentage': '22.0411288558302', 'Y_percentage': '69.6018376722818' },
    { 'estande': '89', 'X_percentage': '20.4659811857362', 'Y_percentage': '69.6018376722818' },
    { 'estande': '90', 'X_percentage': '19.4815138919274', 'Y_percentage': '69.5799606213082' },
    { 'estande': '91', 'X_percentage': '17.4031940494421', 'Y_percentage': '69.6018376722818' },
    { 'estande': '92', 'X_percentage': '16.3530956027128', 'Y_percentage': '69.6018376722818' },
    { 'estande': '93', 'X_percentage': '14.8435790855393', 'Y_percentage': '69.5799606213082' },
    { 'estande': '94', 'X_percentage': '13.8153576897834', 'Y_percentage': '69.5799606213082' },
    { 'estande': '95', 'X_percentage': '12.2620870706629', 'Y_percentage': '69.5799606213082' },
    { 'estande': '96', 'X_percentage': '11.233865674907', 'Y_percentage': '69.5799606213082' },
    { 'estande': '97', 'X_percentage': '9.70247210676001', 'Y_percentage': '69.5799606213082' },
    { 'estande': '98', 'X_percentage': '8.67425071100416', 'Y_percentage': '69.5799606213082' },
    { 'estande': '99', 'X_percentage': '11.233865674907', 'Y_percentage': '66.2984029752789' },
    { 'estande': '100', 'X_percentage': '12.2402100196893', 'Y_percentage': '66.2765259243054' },
    { 'estande': '101', 'X_percentage': '13.8153576897834', 'Y_percentage': '66.3202800262525' },
    { 'estande': '102', 'X_percentage': '14.8217020345657', 'Y_percentage': '66.2984029752789' },
    { 'estande': '103', 'X_percentage': '16.3093415007657', 'Y_percentage': '66.3202800262525' },
    { 'estande': '104', 'X_percentage': '17.3813169984686', 'Y_percentage': '66.2765259243054' },
    { 'estande': '105', 'X_percentage': '18.9199956245898', 'Y_percentage': '66.3348720192518' },
    { 'estande': '106', 'X_percentage': '47.4039159921243', 'Y_percentage': '66.4369503390943' },
    { 'estande': '107', 'X_percentage': '48.468606431853', 'Y_percentage': '66.4515423320936' },
    { 'estande': '108', 'X_percentage': '50.0656311529206', 'Y_percentage': '66.4515423320936' },
    { 'estande': '109', 'X_percentage': '51.0938525486764', 'Y_percentage': '66.4296652811201' },
    { 'estande': '110', 'X_percentage': '52.6252461168235', 'Y_percentage': '66.4515423320936' },
    { 'estande': '111', 'X_percentage': '53.6534675125793', 'Y_percentage': '66.4515423320936' },
    { 'estande': '112', 'X_percentage': '55.0754758258587', 'Y_percentage': '66.4296652811201' },
    { 'estande': '113', 'X_percentage': '56.1474513235616', 'Y_percentage': '66.4734193830672' },
    { 'estande': '114', 'X_percentage': '57.7225989936557', 'Y_percentage': '66.4515423320936' },
    { 'estande': '115', 'X_percentage': '58.7508203894115', 'Y_percentage': '66.4734193830672' },
    { 'estande': '116', 'X_percentage': '58.772697440385', 'Y_percentage': '64.6576241522643' },
    { 'estande': '117', 'X_percentage': '57.7225989936557', 'Y_percentage': '64.6576241522643' },
    { 'estande': '118', 'X_percentage': '56.2130824764822', 'Y_percentage': '64.6357471012908' },
    { 'estande': '119', 'X_percentage': '55.1848610807263', 'Y_percentage': '64.6357471012908' },
    { 'estande': '120', 'X_percentage': '53.6097134106323', 'Y_percentage': '64.6795012032378' },
    { 'estande': '121', 'X_percentage': '52.6033690658499', 'Y_percentage': '64.6357471012908' },
    { 'estande': '122', 'X_percentage': '51.0282213957558', 'Y_percentage': '64.6576241522643' },
    { 'estande': '123', 'X_percentage': '50.0437541019471', 'Y_percentage': '64.6138700503172' },
    { 'estande': '124', 'X_percentage': '48.4904834828265', 'Y_percentage': '64.6357471012908' },
    { 'estande': '125', 'X_percentage': '47.4622620870707', 'Y_percentage': '64.6357471012908' },
    { 'estande': '126', 'X_percentage': '47.4403850360971', 'Y_percentage': '61.5073288120761' },
    { 'estande': '127', 'X_percentage': '48.4467293808795', 'Y_percentage': '61.5073288120761' },
    { 'estande': '128', 'X_percentage': '50', 'Y_percentage': '61.5073288120761' },
    { 'estande': '129', 'X_percentage': '51.0719754977029', 'Y_percentage': '61.5292058630497' },
    { 'estande': '130', 'X_percentage': '53.8284839203675', 'Y_percentage': '61.5948370159703' },
    { 'estande': '131', 'X_percentage': '54.8785823670969', 'Y_percentage': '61.5948370159703' },
    { 'estande': '132', 'X_percentage': '56.3880988842704', 'Y_percentage': '61.5729599649967' },
    { 'estande': '133', 'X_percentage': '57.4381973309998', 'Y_percentage': '61.5948370159703' },
    { 'estande': '134', 'X_percentage': '59.0133450010939', 'Y_percentage': '61.5510829140232' },
    { 'estande': '135', 'X_percentage': '62.9949682782761', 'Y_percentage': '51.2251148545176' },
    { 'estande': '136', 'X_percentage': '62.951214176329', 'Y_percentage': '49.62809013345' },
    { 'estande': '137', 'X_percentage': '62.907460074382', 'Y_percentage': '48.6873769415883' },
    { 'estande': '138', 'X_percentage': '60.5520236272151', 'Y_percentage': '25.8039816232772' },
    { 'estande': '139', 'X_percentage': '60.508269525268', 'Y_percentage': '24.7392911835485' },
    { 'estande': '140', 'X_percentage': '60.5374535112667', 'Y_percentage': '23.2078976154014' },
    { 'estande': '141', 'X_percentage': '60.4936994093196', 'Y_percentage': '22.1432290527237' },
    { 'estande': '142', 'X_percentage': '60.5374535112667', 'Y_percentage': '20.5534893896303' },
    { 'estande': '143', 'X_percentage': '60.508269525268', 'Y_percentage': '19.4887989499016' },
    { 'estande': '144', 'X_percentage': '60.508269525268', 'Y_percentage': '17.8990811638591' },
    { 'estande': '145', 'X_percentage': '60.5374535112667', 'Y_percentage': '16.9364909210238' },
    { 'estande': '146', 'X_percentage': '60.508269525268', 'Y_percentage': '15.3321592649311' },
    { 'estande': '147', 'X_percentage': '60.5520236272151', 'Y_percentage': '14.3549989061475' },
    { 'estande': '148', 'X_percentage': '60.5374535112667', 'Y_percentage': '12.7360971341063' },
    { 'estande': '149', 'X_percentage': '60.5812076132137', 'Y_percentage': '11.6859986873769' },
    { 'estande': '150', 'X_percentage': '60.5520236272151', 'Y_percentage': '10.0670969153358' },
    { 'estande': '151', 'X_percentage': '60.5374535112667', 'Y_percentage': '9.06075257055349' },
    { 'estande': '152', 'X_percentage': '60.5666156202144', 'Y_percentage': '7.50017501640779' },
    { 'estande': '153', 'X_percentage': '64.314876394662', 'Y_percentage': '7.41266681251367' },
    { 'estande': '154', 'X_percentage': '64.2857142857143', 'Y_percentage': '9.06075257055349' },
    { 'estande': '155', 'X_percentage': '64.2711222927149', 'Y_percentage': '10.1400131262306' },
    { 'estande': '156', 'X_percentage': '64.2273681907679', 'Y_percentage': '11.7151607963247' },
    { 'estande': '157', 'X_percentage': '64.3003062787136', 'Y_percentage': '12.7069131481076' },
    { 'estande': '158', 'X_percentage': '64.2857142857143', 'Y_percentage': '14.3112448042004' },
    { 'estande': '159', 'X_percentage': '64.314876394662', 'Y_percentage': '15.3175891489827' },
    { 'estande': '160', 'X_percentage': '64.2857142857143', 'Y_percentage': '16.9218989280245' },
    { 'estande': '161', 'X_percentage': '64.314876394662', 'Y_percentage': '17.9719973747539' },
    { 'estande': '162', 'X_percentage': '64.2857142857143', 'Y_percentage': '19.547145044848' },
    { 'estande': '163', 'X_percentage': '64.2711222927149', 'Y_percentage': '20.5534893896303' },
    { 'estande': '164', 'X_percentage': '64.2857142857143', 'Y_percentage': '22.1432290527237' },
    { 'estande': '165', 'X_percentage': '64.314876394662', 'Y_percentage': '23.2078976154014' },
    { 'estande': '166', 'X_percentage': '64.3294683876613', 'Y_percentage': '24.7830452854955' },
    { 'estande': '167', 'X_percentage': '64.314876394662', 'Y_percentage': '25.8331437322249' },
    { 'estande': '168', 'X_percentage': '66.1233865674907', 'Y_percentage': '42.9847516954715' },
    { 'estande': '169', 'X_percentage': '66.1525486764384', 'Y_percentage': '41.4096040253774' },
    { 'estande': '170', 'X_percentage': '66.1233865674907', 'Y_percentage': '40.4032596805951' },
    { 'estande': '171', 'X_percentage': '66.1087945744914', 'Y_percentage': '38.7989499015533' },
    { 'estande': '172', 'X_percentage': '66.1233865674907', 'Y_percentage': '37.7634434478232' },
    { 'estande': '173', 'X_percentage': '66.1525486764384', 'Y_percentage': '36.2028658936775' },
    { 'estande': '174', 'X_percentage': '66.1087945744914', 'Y_percentage': '35.1673594399475' },
    { 'estande': '175', 'X_percentage': '66.1233865674907', 'Y_percentage': '33.5484576679064' },
    { 'estande': '176', 'X_percentage': '66.1671406694378', 'Y_percentage': '32.5275213301247' },
    { 'estande': '177', 'X_percentage': '66.1233865674907', 'Y_percentage': '30.9378035440823' },
    { 'estande': '178', 'X_percentage': '66.1379785604901', 'Y_percentage': '29.9314591992999' },
    { 'estande': '179', 'X_percentage': '66.1233865674907', 'Y_percentage': '25.8331437322249' },
    { 'estande': '180', 'X_percentage': '66.1379785604901', 'Y_percentage': '24.7684751695471' },
    { 'estande': '181', 'X_percentage': '66.1525486764384', 'Y_percentage': '23.1641435134544' },
    { 'estande': '182', 'X_percentage': '66.1379785604901', 'Y_percentage': '22.1286370597243' },
    { 'estande': '183', 'X_percentage': '66.1233865674907', 'Y_percentage': '20.5534893896303' },
    { 'estande': '184', 'X_percentage': '66.1525486764384', 'Y_percentage': '19.5179829359002' },
    { 'estande': '185', 'X_percentage': '66.1379785604901', 'Y_percentage': '17.9282432728068' },
    { 'estande': '186', 'X_percentage': '66.1525486764384', 'Y_percentage': '16.9218989280245' },
    { 'estande': '187', 'X_percentage': '66.1087945744914', 'Y_percentage': '15.288405162984' },
    { 'estande': '188', 'X_percentage': '66.1379785604901', 'Y_percentage': '14.2674907022533' },
    { 'estande': '189', 'X_percentage': '66.1087945744914', 'Y_percentage': '12.7069131481076' },
    { 'estande': '190', 'X_percentage': '66.1233865674907', 'Y_percentage': '11.6714066943776' },
    { 'estande': '191', 'X_percentage': '66.1087945744914', 'Y_percentage': '10.0379129293371' },
    { 'estande': '192', 'X_percentage': '66.1379785604901', 'Y_percentage': '9.04616057755415' },
    { 'estande': '193', 'X_percentage': '66.1525486764384', 'Y_percentage': '7.41266681251367' },
    { 'estande': '194', 'X_percentage': '69.8424852329906', 'Y_percentage': '6.07088164515423' },
    { 'estande': '195', 'X_percentage': '69.8424852329906', 'Y_percentage': '7.12098009188361' },
    { 'estande': '196', 'X_percentage': '69.8424852329906', 'Y_percentage': '8.73988186392474' },
    { 'estande': '197', 'X_percentage': '69.8278932399912', 'Y_percentage': '9.80457230365347' },
    { 'estande': '198', 'X_percentage': '69.8278932399912', 'Y_percentage': '11.3505359877489' },
    { 'estande': '199', 'X_percentage': '69.8570772259899', 'Y_percentage': '13.9466199956246' },
    { 'estande': '200', 'X_percentage': '69.8424852329906', 'Y_percentage': '15.0258805513017' },
    { 'estande': '201', 'X_percentage': '69.8278932399912', 'Y_percentage': '16.6156202143951' },
    { 'estande': '202', 'X_percentage': '69.8716473419383', 'Y_percentage': '17.6365565521768' },
    { 'estande': '203', 'X_percentage': '69.8133231240429', 'Y_percentage': '19.2262743382192' },
    { 'estande': '204', 'X_percentage': '69.8278932399912', 'Y_percentage': '21.7348501422008' },
    { 'estande': '205', 'X_percentage': '69.8278932399912', 'Y_percentage': '22.7703565959309' },
    { 'estande': '206', 'X_percentage': '69.8278932399912', 'Y_percentage': '24.2871800481295' },
    { 'estande': '207', 'X_percentage': '69.8424852329906', 'Y_percentage': '25.3080945088602' },
    { 'estande': '208', 'X_percentage': '69.8570772259899', 'Y_percentage': '26.9561802669' },
    { 'estande': '209', 'X_percentage': '69.8424852329906', 'Y_percentage': '29.9168672063006' },
    { 'estande': '210', 'X_percentage': '69.8570772259899', 'Y_percentage': '30.9523736600306' },
    { 'estande': '211', 'X_percentage': '69.8278932399912', 'Y_percentage': '32.5275213301247' },
    { 'estande': '212', 'X_percentage': '69.8570772259899', 'Y_percentage': '33.5776197768541' },
    { 'estande': '213', 'X_percentage': '69.8278932399912', 'Y_percentage': '35.1236053380004' },
    { 'estande': '214', 'X_percentage': '69.8716473419383', 'Y_percentage': '37.9676219645592' },
    { 'estande': '215', 'X_percentage': '69.8716473419383', 'Y_percentage': '39.0468825202363' },
    { 'estande': '216', 'X_percentage': '69.8424852329906', 'Y_percentage': '40.5782760883833' },
    { 'estande': '217', 'X_percentage': '69.8424852329906', 'Y_percentage': '41.6138044191643' },
    { 'estande': '218', 'X_percentage': '69.8570772259899', 'Y_percentage': '43.174360096259' },
]

export default coords;