/**
 * As the form returns the chosen grades in string format, this function converts these values ​​to numbers.
 * @param {{comentario_pontos_positivos:string,
    comentario_melhorias:string,
    comentario_duvidas:string,
    comentario_sugestoes:string,
    nota_planejamento:string,
    nota_execucao:string,
    nota_inovacao:string,
    nota_atitude:string,
    nota_criatividade:string,
    nota_apresentacao:string,
    nota_relatorio:string}} evaluationData 
 * @returns {{comentario_pontos_positivos:string,
    comentario_melhorias:string,
    comentario_duvidas:string,
    comentario_sugestoes:string,
    nota_planejamento:number,
    nota_execucao:number,
    nota_inovacao:number,
    nota_atitude:number,
    nota_criatividade:number,
    nota_apresentacao:number,
    nota_relatorio:number}}
 */
const setStrGradesToNumber = evaluationData => {
    // check if evaluationData is an object 
    if (!typeof evaluationData === 'object' || Array.isArray(evaluationData) || evaluationData == null) {
        throw new TypeError(`Invalid value: Expected an object, but received: (${evaluationData}, ${typeof evaluationData})`);
    }

    // checkl if object is empty
    if (Object.keys(evaluationData).length === 0) {
        throw new TypeError(`Invalid value: Expected an object with values`);
    }

    const processedObject = { ...evaluationData };

    for (const [key, value] of Object.entries(evaluationData)) {
        if (key.includes("nota")) {
            processedObject[key] = Number.parseInt(value);
        } else {
            processedObject[key] = value;
        }
    }

    return processedObject;
}

export {
    setStrGradesToNumber 
}