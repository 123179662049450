import {
    EVALUATOR_FILLING,
    EXPIRED,
    FILL_TEAM,
    OPENED
} from "commom/consts/evaluations/const-status";

const evaluationMap = new Map();

// Choices of: ProjetoAvaliador -> ficha_digital_status
evaluationMap.set(OPENED, "Disponível para preenchimento"); //in backend: Código válido para preencher ficha online
evaluationMap.set(EVALUATOR_FILLING, "Ficha preenchida");
evaluationMap.set(FILL_TEAM, "Ficha preenchida pela equipe Febrace");
evaluationMap.set(EXPIRED, "Ficha não preenchida");

const getEvaluationStatusText = status => evaluationMap.get(status);

export {
    getEvaluationStatusText
}
