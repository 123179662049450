import { Button, Offcanvas } from "react-bootstrap";
import HighlightImage from "components/highlight-image";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { useRef, useState } from "react";
import { BsZoomIn } from "react-icons/bs";


/**
 * A helper component to zoom into hightlight when the component and image render
 *  
 * @typedef MapTransformProps
 * @property {{x: number, y:number}} hightlightCoords
 * 
 * @param {MapTransformProps} props 
 * @returns 
 */
const MapTransform = ({ hightlightCoords: { x, y } }) => {
    const transformComponentRef = useRef();
    const [enableZoomButton, setEnableZoomButton] = useState(false);

    const zoomToHighlight = () => {
        if (!transformComponentRef.current || (!x || !y)) return;

        const { zoomToElement } = transformComponentRef.current;

        // The `highlight` is in highlight.jsx; search for id="highlight"
        zoomToElement("highlight");
    }

    const onImgLoad = () => {
        setEnableZoomButton(true);
    }

    return (
        <>
            <div className="d-flex justify-content-center" >
                <Button
                    onClick={zoomToHighlight}
                    variant="lilac"
                    disabled={!enableZoomButton}
                    className="mb-5 w-100 d-flex justify-content-center align-items-center"
                    style={{ maxWidth: 500 }}>
                    {enableZoomButton ? <><BsZoomIn />&nbsp;Visualizar estande</> : 'Aguarde...'}
                </Button>
            </div>
            <TransformWrapper
                ref={transformComponentRef}
                velocityAnimation={{
                    animationTime: 1000,
                    animationType: "easeInOutQuart"
                }}
            >
                <TransformComponent>

                    <HighlightImage
                        top={y}
                        left={x}
                        onImgLoad={onImgLoad}
                    />
                </TransformComponent>
            </TransformWrapper>
        </>
    )
}

const OffcanvasMap = (props) => {

    const boxX = props.x ? props.x - 1 : undefined;
    const boxY = props.y ? props.y - 1 : undefined;


    return (
        <Offcanvas
            show={props.showMap}
            onHide={props.onHide}
            placement="bottom"
            className='h-100'

        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className="h6">
                    {props.title}
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body >
                <MapTransform
                    hightlightCoords={{
                        x: boxX,
                        y: boxY
                    }} />
            </Offcanvas.Body>
        </Offcanvas>
    )
}
export default OffcanvasMap;