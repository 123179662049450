import { Container } from "react-bootstrap";
import FebraceLogo from "assets/logos/febrace-logo.png";
import style from "./login.module.css";
import LoginForm from "components/forms/login-form";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { useEffect } from "react";
import useUuidStorage from "hooks/useUuidStorage";

const Login = () => {

    const navigate = useNavigate();
    const {getUuid} = useUuidStorage();

    useEffect(()=>{
        const uuid = getUuid();

        if(!uuid) return;

        navigate(`/avaliacao/${uuid}/menu`);
    }, []);

    const onLoginSuccess = ({ data, uuidStr }) => {
        const evaluationUrl = `/avaliacao/${uuidStr}/menu`;

        navigate(evaluationUrl);
    }

    const onLoginError = () => toast.error("Não foi possível autêntica-lo. Por favor, verifique o código e o captcha informado.")

    return (
        <section className="bg-dark-blue h-100 text-light ">
            <Container className="d-flex h-100 flex-column justify-content-center align-items-center">
                <div className="mb-5">
                    <img
                        className={`w-100 ${style.febrace_logo}`}
                        src={FebraceLogo}
                        alt="Logo da Feira Brasileira de Ciências e Engenharias"
                    />
                    <h1 className="text-center smilage mt-2">
                        Avaliação
                    </h1>
                </div>

                <LoginForm
                    onLoginError={onLoginError}
                    onLoginSuccess={onLoginSuccess}
                />
            </Container>
        </section>
    )
}

export default Login;