const useUuidStorage = () => {
    const key = "uuid";

    const saveUuid = uuid => {
        localStorage.setItem(key, uuid);
    }

    const getUuid = () => {
        return localStorage.getItem(key);
    }

    const removeUuid = () => {
        localStorage.removeItem(key);
    }

    return {
        saveUuid,
        getUuid,
        removeUuid
    }
}

export default useUuidStorage;