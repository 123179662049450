import api from "lib/axios";

const BASE_URL = 'avaliadores';

const evaluationClient = {
    getEvaluationSheet: uuidStr => api.get(`${BASE_URL}/obter_ficha_avaliacao/${uuidStr}/`),
    sendEvaluationGrades: (uuidStr, projectId, evaluationGrades) => api.post(`${BASE_URL}/enviar_notas_mobile/${uuidStr}/${projectId}/`, evaluationGrades),
    getSheetGrades: uuidStr => api.get(`${BASE_URL}/obter_avaliacao_finalizada/${uuidStr}/`),
    getEvaluatorSheets: evaluatorUuid => api.get(`${BASE_URL}/obter_fichas_avaliador/${evaluatorUuid}/`)
}

export default evaluationClient;