const OPENED = "aberto";
const EVALUATOR_FILLING = "preenchimento_avaliador";
const FILL_TEAM = "preenchimento_equipe";
const EXPIRED = "expirou";

export {
    OPENED,
    EVALUATOR_FILLING,
    FILL_TEAM,
    EXPIRED
}