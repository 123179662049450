import { GRADES } from "commom/consts/evaluations/consts-evaluations";
import getCriteriaHelpTexts from "commom/help-texts/criteria-help-texts";
import getEvaluationStatusColor from "commom/helpers/evaluation-status-colors";
import { getEvaluationStatusText } from "commom/helpers/evaluation-status-text";
import SquareRadioButton from "components/inputs/square-radio-button";
import useRequestState from "hooks/useRequestState";
import { useEffect, useLayoutEffect, useState } from "react";
import { Alert, Badge, Button, Card, Form, Modal, Spinner } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { AiFillInfoCircle } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setStrGradesToNumber } from "services/form-process/process-evaluation-form";
import style from "./evaluation-form.module.css";

/**
 * @typedef {import("commom/contexts/useEvaluationContext").Sheet} Sheet
 * @typedef {import("commom/contexts/useEvaluationContext").Project} Project
 * 
 * @typedef EvaluationFormFields
 * 
 * @property {string} nota_planejamento
 * @property {string} nota_execucao
 * @property {string} nota_inovacao
 * @property {string} nota_atitude
 * @property {string} nota_criatividade
 * @property {string} nota_apresentacao
 * @property {string} nota_relatorio
 * @property {string} comentario
 */

/**
 * modal to help users which methodology to use to evaluate a project.
 * @param {{ isShow: boolean, title:string, descriptions:Array<string|object>, onHide:()=> void }} 
 *  isShow Used to hide or show the modal; 
 *  
 *  title: The modal title;
 * 
 *  descriptions: Contains information to assist the evaluator; It can be an array of strings or an array of nested objects.,
 *   
 *  onHide<function>: Callback called when the user closes the modal.
 * 
 * @returns 
 */
const ModalHelpEvaluation = ({
    isShow = false,
    title = "",
    descriptions = [],
    onHide
}) => {


    /**
     * Function that helps render simple descriptions and nested descriptions.
     * @param {string} description
     * @returns A Li element or an Ul with nested Li.
     */
    const renderDescription = description => {

        if (typeof description == 'string') {
            return (
                <li key={description}>
                    {description}
                </li>
            )
        }

        return Object.values(description).map(nestedDescription => {
            return (
                <li key={nestedDescription.title}>
                    <b>{nestedDescription.title}</b>

                    <ol>
                        {
                            nestedDescription.descriptionItems.map(description => renderDescription(description))

                        }
                    </ol>
                </li>
            )
        });
    }

    return (
        <Modal show={isShow} fullscreen={true} onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Apoio para avaliação
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>
                    {title}
                </h5>
                <ol className={style.criteriaModalList}>
                    {
                        descriptions.map(renderDescription)
                    }
                </ol>
            </Modal.Body>
        </Modal>
    )
}

const CriteriaNotes = ({ evaluationName, disabled = false, defaultValue, reactHookFormProps: { errors, control } }) => {
    const [selectedGrade, setSelectedGrade] = useState();

    /**
     * An object map to get an error message for a specific criteria field.
     */
    const errorMessages = {
        'nota_planejamento': 'avalie o planejamento',
        'nota_execucao': 'avalie a execução',
        'nota_inovacao': 'avalie a inovação',
        'nota_atitude': 'avalie a atitude científica',
        'nota_criatividade': 'avalie a criatividade',
        'nota_apresentacao': 'avalie a apresentação',
        'nota_relatorio': 'avalie a documentação'
    }

    useLayoutEffect(() => {
        if (defaultValue === "" || defaultValue === null || defaultValue === undefined) return;

        const grade = Number.parseInt(defaultValue);

        setSelectedGrade(grade);
    }, []);

    const onClick = event => {
        const note = Number.parseInt(event.target.value);

        setSelectedGrade(note);
    }

    return (
        <div>
            <div className="d-flex justify-content-between" data-testid={evaluationName}>
                {
                    GRADES.map(note => (
                        <Controller
                            key={`${evaluationName}_${note}`}
                            name={evaluationName}
                            rules={{
                                required: {
                                    value: true,
                                    message: `Atenção, ${errorMessages[evaluationName]}`
                                },

                            }}
                            control={control}
                            defaultValue={defaultValue}
                            render={({ field }) => (
                                <SquareRadioButton
                                    {...field}
                                    id={`${evaluationName}_${note}`}
                                    value={note}
                                    isSelected={selectedGrade === note}
                                    onClick={onClick}
                                    disabled={disabled}
                                >
                                    {note}
                                </SquareRadioButton>
                            )}
                        />

                    ))
                }
            </div>
            {errors[evaluationName] &&
                <Alert variant="danger" className="mt-2">
                    {errors[evaluationName].message}
                </Alert>
            }
        </div>
    )
}

//Total fields in form
const TOTAL_FIELDS = 8;


/**
 * A form to evaluate a projet.
 * @param {{
 * sheet:Sheet,
 * project: Project,
 * onSuccessSubmit: (data: EvaluationFormFields)=> void;
 * onErrorSubmit: (fields: Object) => void,
 * defaultValues: EvaluationFormFields,
 * disabledFields: Array<'nota_planejamento'|'nota_execucao'|'nota_inovacao'|'nota_criatividade'|
 * 'nota_apresentacao'|'nota_relatorio'|'comentario'> * 
 * }} param0 
 * @returns 
 */
const EvaluationForm = ({
    sheet,
    project,
    onSuccessSubmit,
    onErrorSubmit,
    defaultValues = {},
    disabledFields = [] }) => {
    const { isLoading, setIsLoading } = useRequestState({ isLoading: false });
    const { register, handleSubmit, setValue, formState: { errors }, control, watch } = useForm();
    const [criteriaModal, setCriteriaModal] = useState({
        isShow: false,
        title: "",
        descriptions: []
    });
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!!location.hash) return;

        setCriteriaModal({
            isShow: false,
            title: "",
            descriptions: []
        });
    }, [location]);



    /**
     * Called when the form data is valid.
     * @param {*} data - The form inputs. 
     */
    const onValidSubmit = async data => {
        /*Set the data to the format:
            HUM-123:{
                nota_planejamento: 0,
                nota_execucao: 5,
                nota_inovacao: 1,
                ...
            }
        */

        if (allFieldsDisabled) return;

        const gradesProcessed = setStrGradesToNumber(data);

        setIsLoading(true);
        // pass the valid data to callback
        onSuccessSubmit && await onSuccessSubmit({ ...gradesProcessed });
        setIsLoading(false);
    }

    /**
     * Callend when the submit has issues, like: invalid data.
     * @param {*} fields Fields with errors
     */
    const onInvalidSubmit = fields => {
        toast.error("Atenção! Não foi possível enviar sua avaliação, pois há erros no formulário. Verifique os campos e tente novamente.");

        // pass the fields with error to callback
        onErrorSubmit && onErrorSubmit(fields);
    }

    /**
     * Show a modal with  the informations about the clicked item
     */
    const onHelpCriteriaClick = whichCriteria => {
        if (isLoading) return;

        const criteria = getCriteriaHelpTexts({ projectCode: project.code })[whichCriteria];

        setCriteriaModal({
            isShow: true,
            title: criteria.title,
            descriptions: criteria.descriptionItems
        });

        const { pathname } = location;

        navigate(`${pathname}#${whichCriteria}`);
    }

    /**
     * Get a field default value.
     * @param {'nota_planejamento'|'nota_execucao'|'nota_inovacao'|'nota_criatividade'|
 * 'nota_apresentacao'|'nota_relatorio'|'comentario'} fieldName 
     * @returns 
     */
    const getDefaultValue = fieldName => {
        if (!(fieldName in defaultValues)) {
            return "";
        }

        return defaultValues[fieldName];
    }

    const watchCommentary = watch("comentario", getDefaultValue("comentario")) || "";

    const allFieldsDisabled = disabledFields.length === TOTAL_FIELDS;

    const hasProjectBox = Boolean(project.box)

    const projectDisplay = hasProjectBox? `Estande ${project.box}` : project.code

    return (
        <>
            <Card>
                <Card.Header>
                    <b>Avaliação - {projectDisplay}</b>
                </Card.Header>
                <Card.Body>
                    <div>
                        <p className="m-0 fw-bold">
                            Status
                        </p>
                        <Badge bg={getEvaluationStatusColor(sheet?.status)}>
                            {getEvaluationStatusText(sheet?.status)}
                        </Badge>
                    </div>
                    <p className="mt-3 mb-0">
                        {project.title}
                    </p>
                </Card.Body>
            </Card>
            <Form onSubmit={handleSubmit(onValidSubmit, onInvalidSubmit)} className="py-2">
                <Card className="mb-3">
                    <Card.Header className="fw-bold text-uppercase">
                        Projeto
                    </Card.Header>
                    <Card.Body>
                        <fieldset className="mb-3">
                            <legend>
                                <Card.Title className="fw-normal">
                                    Planejamento <span role="button"><AiFillInfoCircle
                                        color="var(--lilac)"
                                        onClick={() => onHelpCriteriaClick('planning')}
                                    /></span>
                                </Card.Title>
                            </legend>
                            <CriteriaNotes
                                defaultValue={getDefaultValue("nota_planejamento")}
                                disabled={isLoading || disabledFields.includes("nota_planejamento")}
                                evaluationName="nota_planejamento"
                                reactHookFormProps={{
                                    setValue,
                                    errors,
                                    control
                                }}

                            />
                        </fieldset>

                        <fieldset className="mb-3">
                            <legend>
                                <Card.Title className="fw-normal">
                                    Execução <span role="button"><AiFillInfoCircle
                                        color="var(--lilac)"
                                        onClick={() => onHelpCriteriaClick('execution')}
                                    /></span>
                                </Card.Title>
                            </legend>
                            <CriteriaNotes
                                defaultValue={getDefaultValue("nota_execucao")}
                                disabled={isLoading || disabledFields.includes("nota_execucao")}
                                evaluationName="nota_execucao"
                                reactHookFormProps={{
                                    setValue,
                                    errors,
                                    control
                                }}
                            />
                        </fieldset>

                        <fieldset className="mb-3">
                            <legend>
                                <Card.Title className="fw-normal">
                                    Inovação <span role="button"><AiFillInfoCircle
                                        color="var(--lilac)"
                                        onClick={() => onHelpCriteriaClick('innovation')}
                                    /></span>
                                </Card.Title>
                            </legend>
                            <CriteriaNotes
                                defaultValue={getDefaultValue("nota_inovacao")}
                                disabled={isLoading || disabledFields.includes("nota_inovacao")}
                                evaluationName="nota_inovacao"
                                reactHookFormProps={{
                                    setValue,
                                    errors,
                                    control
                                }}
                            />
                        </fieldset>
                    </Card.Body>
                </Card>


                <Card className="mb-3">
                    <Card.Header className="fw-bold text-uppercase">
                        Estudantes
                    </Card.Header>
                    <Card.Body>
                        <fieldset className="mb-3">
                            <legend>
                                <Card.Title className="fw-normal">
                                    Atitude Científica  <span role="button"><AiFillInfoCircle
                                        color="var(--lilac)"
                                        onClick={() => onHelpCriteriaClick('scientificAttitude')}
                                    /></span>
                                </Card.Title>
                            </legend>
                            <CriteriaNotes
                                defaultValue={getDefaultValue("nota_atitude")}
                                disabled={isLoading || disabledFields.includes("nota_atitude")}
                                evaluationName="nota_atitude"
                                reactHookFormProps={{
                                    setValue,
                                    errors,
                                    control
                                }}

                            />
                        </fieldset>

                        <fieldset className="mb-3">
                            <legend>
                                <Card.Title className="fw-normal">
                                    Criatividade <span role="button"><AiFillInfoCircle
                                        color="var(--lilac)"
                                        onClick={() => onHelpCriteriaClick('creativity')}
                                    /></span>
                                </Card.Title>
                            </legend>
                            <CriteriaNotes
                                defaultValue={getDefaultValue("nota_criatividade")}
                                disabled={isLoading || disabledFields.includes("nota_criatividade")}
                                evaluationName="nota_criatividade"
                                reactHookFormProps={{
                                    setValue,
                                    errors,
                                    control
                                }}
                            />
                        </fieldset>

                        <fieldset className="mb-3">
                            <legend>
                                <Card.Title className="fw-normal">
                                    Apresentação oral <span role="button"><AiFillInfoCircle
                                        color="var(--lilac)"
                                        onClick={() => onHelpCriteriaClick('oralPresentation')}
                                    /></span>
                                </Card.Title>
                            </legend>
                            <CriteriaNotes
                                defaultValue={getDefaultValue("nota_apresentacao")}
                                disabled={isLoading || disabledFields.includes("nota_apresentacao")}
                                evaluationName="nota_apresentacao"
                                reactHookFormProps={{
                                    setValue,
                                    errors,
                                    control
                                }}
                            />
                        </fieldset>
                    </Card.Body>
                </Card>

                <Card className="mb-3">
                    <Card.Header className="fw-bold text-uppercase">
                        Materiais
                    </Card.Header>
                    <Card.Body>
                        <fieldset>
                            <legend>
                                <Card.Title className="fw-normal">
                                    Documentação <span role="button"><AiFillInfoCircle
                                        color="var(--lilac)"
                                        onClick={() => onHelpCriteriaClick('documentation')}
                                    /></span>
                                </Card.Title>
                            </legend>
                            <CriteriaNotes
                                defaultValue={getDefaultValue("nota_relatorio")}
                                disabled={isLoading || disabledFields.includes("nota_relatorio")}
                                evaluationName="nota_relatorio"
                                reactHookFormProps={{
                                    setValue,
                                    errors,
                                    control
                                }}
                            />
                        </fieldset>
                    </Card.Body>
                </Card>

                <Card className="mb-3">
                    <fieldset>
                        <Card.Body>
                            <legend>
                                <b>Comentários para os estudantes</b>
                            </legend>

                            <Form.Floating>
                                <Form.Control
                                    id="comentario"
                                    as="textarea"
                                    defaultValue={getDefaultValue("comentario")}
                                    disabled={isLoading || disabledFields.includes("comentario")}
                                    style={{ height: 150 }}
                                    maxLength={500}
                                    {...register('comentario', {
                                        maxLength: {
                                            message: "Atenção, o comentário deve ter no máximo 500 caracteres",
                                            value: 500
                                        }
                                    })}
                                />
                                <label htmlFor="comentario">Comentários</label>
                            </Form.Floating>
                            <span>
                                {`${watchCommentary}`.length}/500
                            </span>
                            {errors.comentario &&
                                <Alert variant="danger" className="mt-2">
                                    {errors.comentario.message}
                                </Alert>
                            }


                            {!allFieldsDisabled &&
                                <Button
                                    disabled={isLoading}
                                    type="submit"
                                    variant="lilac"
                                    className="w-100 mt-3">
                                    {isLoading ?
                                        <div>
                                            <Spinner
                                                animation="grow"
                                                variant="light"
                                                size="sm"
                                                className="me-3"
                                            />
                                            Aguarde...
                                        </div>
                                        :
                                        'Salvar e continuar'}
                                </Button>
                            }
                        </Card.Body>
                    </fieldset>
                </Card>

            </Form>

            <ModalHelpEvaluation
                descriptions={criteriaModal.descriptions}
                isShow={criteriaModal.isShow}
                title={criteriaModal.title}
                onHide={() => {
                    setCriteriaModal({
                        descriptions: [],
                        isShow: false,
                        title: ""
                    });

                    navigate(-1);
                }
                }
            />


        </>
    )
}

export default EvaluationForm;